import { useState } from 'react';
import type { FormEvent } from 'react';
import { FieldWrapper, ForgotPassword, Valid } from './styled';
import { Textfield, useIsMounted } from '@simplywallst/ui-core';
import { Button } from '@simplywallst/ui-core';
import {
  ROUTE_ACCOUNT_RECOVERY,
  ROUTE_ACCOUNT_RECOVERY_LOCALE,
} from '@/constants/routes';
import { validateEmailAddress } from '@utilities/validation';
import If from '@components/If';
import { useLogin } from '@/hooks/useLogin';
import { useTracking } from 'react-tracking';
import loadable from '@loadable/component';
import { useTranslation } from '@/hooks/useTranslation';
import { useLocale } from '@/hooks/useLocale';

const AuthenticationErrorMessages = loadable(
  () => import('../AuthenticationErrorMessages'),
  {
    resolveComponent: (c) => c.AuthenticationErrorMessages,
  }
);

interface Props {
  disable?: boolean;
  loading?: boolean;
  onSuccess?: () => void;
}

export const LoginForm = (props: Props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { trackEvent, Track } = useTracking({
    type: 'track',
    subject: 'loginForm',
  });

  const onLoginError = () => trackEvent({ action: 'failure' });
  const {
    mutate: login,
    status: loginStatus,
    error: loginError,
  } = useLogin({
    onSuccess: props.onSuccess,
    onError: onLoginError,
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login({ type: 'sws', username: email, password });
  };

  const handleEmailBlur = () => {
    if (!validateEmailAddress(email) && email !== '') {
      setErrorMessage('Invalid email address');
    }
  };

  const t = useTranslation();
  const isMounted = useIsMounted();
  const locale = useLocale();

  const formDisabled = loginStatus === 'pending' || !isMounted;

  return (
    <Track>
      {loginStatus === 'error' && (
        <AuthenticationErrorMessages
          selectorId="login-notification-error"
          error={loginError}
          email={email}
        />
      )}
      <form method="post" onSubmit={handleSubmit}>
        <FieldWrapper>
          <Textfield
            type="email"
            name="username"
            label={t('app.modal.registration.form.email.label')}
            autoComplete="username"
            placeholder="e.g. you@example.com"
            disabled={formDisabled}
            onBlur={handleEmailBlur}
            onChange={(e) => {
              setErrorMessage('');
              setEmail(e.currentTarget.value);
            }}
            error={errorMessage}
            value={email}
            postfix={
              <If match={validateEmailAddress(email) && email !== ''}>
                {() => <Valid data-cy-id="username-check-icon" />}
              </If>
            }
            styleType="standardReverse"
          />
        </FieldWrapper>
        <FieldWrapper>
          <Textfield
            name="password"
            type="password"
            label={t('app.modal.login.form.password.label')}
            autoComplete="current-password"
            placeholder="e.g. ********"
            disabled={formDisabled}
            onChange={(e) => {
              setPassword(e.currentTarget.value);
            }}
            value={password}
            styleType="standardReverse"
          />
          <ForgotPassword
            to={
              locale === 'en'
                ? { type: ROUTE_ACCOUNT_RECOVERY }
                : {
                    type: ROUTE_ACCOUNT_RECOVERY_LOCALE,
                    payload: { locale },
                  }
            }
            data-cy-id="link-forgot-password"
          >
            {t('app.modal.login.form.forgotPassword.label')}
          </ForgotPassword>
        </FieldWrapper>
        <Button
          styleType="louderLarge"
          display="block"
          isLoading={props.loading}
          disabled={formDisabled || errorMessage !== ''}
          type="submit"
          data-cy-id="button-submit-login"
        >
          {t('app.modal.login.form.submit.label')}
        </Button>
      </form>
    </Track>
  );
};
